// This document ready syntax supports use of the $ alias in jQuery no conflict mode. See README.md for more info and alternatives.
jQuery( document ).ready( function( $ ) {
  //desktop colors
  var colorDesk = $(".headerDesk").find('.sub-menu li a').css('color');
  var backgroundDesk = $(".headerDesk").find('.mainNav').css('background-color');
  var backgroundOriginalDesk = $(".headerDesk").find('.sub-menu').css('background-color');

  //mobile colors
  var colorMob = $(".headerMobile--main").css('color');
  var backgroundMob = $(".headerMobile").css('background-color');

  //desktop: animation for submenu
  $(".headerDesk").find(".menu-item").hover(
    function() {
      if($(this).find('.sub-menu').length !== 0){
        $(this).addClass("dropdown");
        $(this).children(".sub-menu").stop(true, true).slideDown( "slow", function(){});
      }
    }, function() {
      $(this).removeClass("dropdown");
      $(this).children(".sub-menu").slideUp( "slow", function(){});
    }
  );

  //desktop: changing background and text color on hover
  $(".headerDesk").find(".sub-menu .menu-item").hover(
    function() {
      $(this).children('a').css('color', backgroundDesk);
      $(this).css('background-color', colorDesk);
    }, function() {
      $(this).children('a').css('color', colorDesk);
      $(this).css('background-color', backgroundOriginalDesk);
    }
  );

  $(".headerMobile").find(".menu-item-has-children").append("<div class='toggle'><i class='fal fa-angle-down'></i></div>");

  //animation for mobile menu
  // $(".headerMobile").find(".menu-item-has-children").click(function() {
  //   $(this).children(".sub-menu").stop(true, true).slideToggle( "slow", function(){});
  //   $(this).toggleClass("dropdown");
  // });
  //
  $(".toggle").click(function() {
    $(this).siblings(".sub-menu").stop(true, true).slideToggle( "slow", function(){});
    $(this).toggleClass("dropdown");
  });

  $(".headerMobile--images-icon").click(function() {
    //switching visible icon
    $(".headerMobile--images-icon img").toggleClass("active");
    //putting white background over page behind menu
    $(".wrapper").toggleClass("inactive");
    //animation for overlay and menu
    $(".overlay").stop(true, true).toggle();
    $(".headerMobile--main").stop(true, true).slideToggle( "slow", function(){});
    //adding class to menu for scrolling capability
    $(".headerMobile").toggleClass("active");
  });

  //changing menu colors on click
  $(".headerMobile").find(".menu-item").click(function(e) {
    var colorChange = $(this).css('color');
    var backgroundChange = $(this).css('background-color');
    $(this).children('a').css('color', backgroundChange);
    $(this).css('color', backgroundChange);
    $(this).css('background-color', colorChange);
    $(".sub-menu").css('color', colorMob);
    $(".sub-menu").css('background-color', backgroundMob);

  });

  //counter for dropdown menu ids
	var counter = 1;

//adding ids to all list items
	$('.menu').children('.menu-item').each(function(i, obj) {
		$(this).attr('id', "menu" + counter);
		counter++;
	});

//adding skip links to items with submenus
	$('.menu-item-has-children').each(function(i, obj) {
		var nextID = $(this).attr('id');
		nextID = nextID.replace('menu', '');
		nextID = parseInt(nextID);
		nextID = nextID + 1;
		$(this).children('a:first-child').after('<a href="#menu' + nextID + '" class="skip-link">Skip submenu to next menu item</a>');
	});

  $('.menu-item').each(function() {
      $(this).attr('role', 'menuitem');
  });

  $('.sub-menu').each(function() {
      $(this).attr('role', 'menu');
  });

  $('.menu').each(function() {
      $(this).attr('role', 'menu');
  });

//if submenu is in focus, show dropdown
	$('.menu-item-has-children').children('a').focus(function(i, obj) {
		$('.menu-item-has-children').children('.sub-menu').css('display', 'none');
		$(this).siblings('.sub-menu').css('display', 'block');
	});

//if menu item does not have submenu, hide all submenus
	$('.menu-item').children('a').focus(function(i, obj) {
		if ($(this).siblings('.sub-menu').length == 0 && $(this).parent('li').parent().hasClass('menu')){
			$('.menu-item-has-children').children('.sub-menu').css('display', 'none');
		}
	});

//if in search, hide submenus
$('.menu-item-has-children').on('focusout', function(i, obj) {
  setTimeout(function () {
      if ($(this).find(':focus').length || $(this).is(':focus')) {
      } else {
        $(this).children('.sub-menu').css('display', 'none');
        console.log('closing');
			}
  }, 300);
});

//submenu skip link functionality
	$('button.skip-link').click(function(i, obj) {
		var id = $(this).attr('id');
		console.log('#' + id + '.menu-item');
		$('#' + id + '.menu-item').children('a').focus();
	})


} ); // end no-conflict document ready
