// This document ready syntax supports use of the $ alias in jQuery no conflict mode. See README.md for more info and alternatives.
jQuery( document ).ready( function( $ ) {

  // - - - - -
  // Global stack
  // - - - - -
  // get DOM elements
  const HTML = dom('html');
  const MODAL_PANEL = dom('div.modal-panel');
  // open modal
  function openModal(s) {
    HTML.classList.add('modal-out');
    MODAL_PANEL.appendChild(s.cloneNode(true));
  };
  // close modal
  function closeModal() {
    HTML.classList.remove('modal-out');
    setTimeout(() => {
      while (MODAL_PANEL.firstChild) {
        MODAL_PANEL.removeChild(MODAL_PANEL.firstChild);
      }
    }, 300);
  };
  // breakpoints (from ../sass/components/_variables.scss)
  const breakpoints = {
    site_min_width: 320,
    mini: 375,
    small: 560,
    mobile: 768,
    tablet: 1024,
    medium_screens: 1200,
    site_max_width: 1400,
  };
  // environment recognition
  const env = {
    is_ie: (window.navigator.userAgent.indexOf('Edge') > 0 || window.navigator.userAgent.indexOf('Trident/') > 0),
  };
  if (env.is_ie) {
    HTML.classList.add('is_ie');
  }

  // - - - - -
  // Banner functions
  // - - - - -
  $('.banner-background').each(function() {
    if ($(this).hasClass('image')){
      $(this).parents(".banner-tall").css("min-height", "initial");
      $(this).parents(".banner-short").css("min-height", "initial");
      $(this).parents(".banner-thin").css("min-height", "initial");
    } else {
    }
  })

  // - - - - -
  // Newsletter Signup form manipulations
  // - - - - -

  var check = false;

  signup_form();
  function signup_form() {
    // get DOM elements
    const SECTION = dom('section.fc-newsletter_signup');
    const FORM = dom('form', SECTION);
    if (SECTION && FORM) {
      const BIRTHMONTH_BOX = dom('div.news-birth-month', FORM);
      const BIRTHMONTH_INPUT = dom('input', BIRTHMONTH_BOX);
      const BIRTHDATE_BOX = dom('div.news-birth-day', FORM);
      const FORM_TEXT_INPUTS = dom(['input[type="text"], input[type="number"], input[type="email"]']);
      const SUBMIT = dom('div.frm_submit button');
      const TOGGLE = dom('button.toggle-cereal-list');
      const CEREALS_WRAPPER = dom('div.news-cereal');
      const CEREALS_LIST = dom('div.frm_opt_container', CEREALS_WRAPPER);
      const CEREALS_OPTIONS = dom(['div.frm_checkbox'], CEREALS_LIST);
      const CEREALS_CHECKBOXES = CEREALS_OPTIONS.map((cereal_option) => {
        return dom('input', cereal_option);
      });

      // get color data for the newsletter module
      const text_colors = JSON.parse(SECTION.getAttribute('data-color'));

      // wrap the birthmonth and birthdate fields in a div.birthday-box
      const BIRTHDAY_BOX = document.createElement('div');
      BIRTHMONTH_BOX.parentNode.insertBefore(BIRTHDAY_BOX, BIRTHMONTH_BOX);
      BIRTHDAY_BOX.classList.add('birthday-box');
      BIRTHDAY_BOX.appendChild(BIRTHMONTH_BOX);
      BIRTHDAY_BOX.appendChild(BIRTHDATE_BOX);

      // give the birthmonth input's parent's aria-label to the birthmonth input
      BIRTHMONTH_INPUT.setAttribute('aria-label', BIRTHMONTH_BOX.getAttribute('aria-label'));

      // disable the submit button for now
      SUBMIT.setAttribute('disabled', '');

      // add a class "a-like" to the cereal list toggle
      TOGGLE.classList.add('a-like');

      // add an arrow-right icon to the "subscribe" button
      SUBMIT.insertAdjacentHTML('beforeEnd', '<i class="fa fa-arrow-right" aria-hidden="true"></i>');

      // add a fake checkbox to each cereal option
      CEREALS_CHECKBOXES.forEach((checkbox) => {
        checkbox.insertAdjacentHTML('afterEnd', '<div class="fake-checkbox" style="border-color: ' + text_colors.secondary + '"><i class="fa fa-check" aria-hidden="true" style="color: ' + text_colors.secondary +'"></i></div>');
      });


      // open/close the cereal list
      let is_cereal_list_open = false;
      TOGGLE.addEventListener('click', (e) => {
        console.log("clicked")
        e.preventDefault();
        if (is_cereal_list_open && check == false) {
          CEREALS_WRAPPER.setAttribute('style', '');
          FORM.classList.remove('cereal-list-open');
          setTimeout(() => {
            FORM.classList.remove('cereal-list-visible');
          }, 300);
        }
        else {
          CEREALS_WRAPPER.setAttribute('style', 'height: ' + (CEREALS_LIST.offsetHeight) + 'px');
          FORM.classList.add('cereal-list-open');
          FORM.classList.add('cereal-list-visible');
        }
        is_cereal_list_open = !is_cereal_list_open;
        check = false;
      });

      // enable/disable the "subscribe" button, based on whether all required fields are filled
      let are_required_fields_filled = false;
      check_required_fields();
      function check_required_fields() {
        if (FORM_TEXT_INPUTS.find((input) => {
          return (
            (input.parentNode.classList.contains('frm_required_field')) &&
            (input.value === '')
          );
        }) === undefined) {
          if (are_required_fields_filled === false) {
            SUBMIT.removeAttribute('disabled');
            are_required_fields_filled = true;
          }
        }
        else {
          if (are_required_fields_filled === true) {
            SUBMIT.setAttribute('disabled', '');
            are_required_fields_filled = false;
          }
        }
      }
      FORM_TEXT_INPUTS.forEach((input) => {
        if (input.parentNode.classList.contains('frm_required_field')) {
          input.addEventListener('input', check_required_fields);
        }
      });
    }
  };

  function Checkbox_to_RadioButton(box,myEvent){
      if(myEvent == "enter") {
          if(box.prop('checked'))
              box.prop('checked',false);
          else
              box.prop('checked',true);
      }
  }

  $('input:checkbox').keypress(function(event) {
      console.log("input enter");
      check = true;
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == 13) {
          Checkbox_to_RadioButton($(this),"enter");
      }
  });

  $('input:checkbox').click(function(e){
      console.log("input click");
      Checkbox_to_RadioButton($(this),e);
  });


  // - - - - -
  // Nutrition Information modal
  // - - - - -
  nutrition_information();
  function nutrition_information() {
    const NUTRINFO_BUTTONS = dom(['button.nutrinfo']);
    const NUTRINFO_CONTENT = dom(['div.nutrinfo']);
    NUTRINFO_BUTTONS.forEach((button,i) => {
      button.addEventListener('click', () => {
        openModal(NUTRINFO_CONTENT[i]);
      });
    });

    if(window.location.hash === '#nutrition' && NUTRINFO_CONTENT.length === 1) {
      // only trigger on single product page
      openModal(NUTRINFO_CONTENT[0]);
    }
  };

  // - - - - -
  // Buy Online modal
  // - - - - -
  buy_online();
  function buy_online() {
    const BUYONLINE_BUTTONS = dom(['button.buyonline']);
    const BUYONLINE_CONTENT = dom(['div.buyonline']);
    BUYONLINE_BUTTONS.forEach((button,i) => {
      button.addEventListener('click', () => {
        openModal(BUYONLINE_CONTENT[i]);
      });
    });
  };

  // - - - - -
  // Modal setup
  // - - - - -
  setup_modal();
  function setup_modal() {
    check = true;
    dom('div.modal-overlay').addEventListener('click', () => {
      closeModal();
    });
  };

  $(document).on('click', 'img.modal-close-icon', function(e) {
    closeModal();
  });

  // - - - - -
  // Banner slideshow setup
  // - - - - -
  $('.banner-slider').each(function() {
    $(this).slick({
      dots: true,
      adaptiveHeight: true,
    });
  });

  $('.banner-slider').on('afterChange', function () {
    if ($('.slick-current').find('video').length) {
      $('.slick-current').find('.video-desktop').get(0).play();
      $('.slick-current').find('.video-mobile').get(0).play();
    } else {
    }
  });

  $('.banner-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    var current = $(this).find('.slick-current');
    current.html(current.html());
  });


  // - - - - -
  // Prouduct Carousel setup
  // - - - - -
  setup_carousel_products();
  function setup_carousel_products() {
    const CAROUSELS = dom(['section.fc-carousel_products div.slider']);
    CAROUSELS.forEach((carousel) => {
      $(carousel).slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        responsive: [
          {
            breakpoint: breakpoints.tablet + 1,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },{
            breakpoint: breakpoints.mobile + 1,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },{
            breakpoint: breakpoints.mini + 1,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              infinite: false,
            },
          },
        ],
      });
    });
  };

  // - - - - -
  // Social stream setup
  // - - - - -
  setup_social_stream();
  function setup_social_stream() {
    $('div.social-stream').each(function(index, stream) {
  		var $stream = $(stream);
  		var $count = 20;
  		if ($stream.attr('data-social-stream-count')) {
  			$count = $stream.attr('data-social-stream-count');
  		}
  		var opts = {
  			days: Infinity,
  			limit: $count,
  			external: true,
  			filter: false,
  			controls: false,
  			rotate: {
  				delay: 0
  			},
  			wall: false,
  			cache: true,
  			feeds: {}
  		};

  		if (parseInt($stream.attr('data-social-stream-limit')) > 0) {
  			opts.limit = parseInt($stream.attr('data-social-stream-limit'));
  		}

  		if ($stream.attr('data-social-stream-fb') !== '') {
  			opts.feeds.facebook = {
  				id: $stream.attr('data-social-stream-fb'),
  				feed: 'posts',
  				url: '/wp-content/themes/spoke-theme/social-stream/facebook.php'
  			}
  		}

      opts.onLoaded = function(carousel) {
        $(carousel).slick({
          slidesToShow: 5,
          slidesToScroll: 5,
          responsive: [
            {
              breakpoint: breakpoints.tablet + 1,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },{
              breakpoint: breakpoints.mobile + 1,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },{
              breakpoint: breakpoints.small + 1,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },{
              breakpoint: breakpoints.mini + 1,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
      }

  		$stream.dcSocialStream(opts);
  	});
  };

  // - - - - -
  // Announcement bar
  // - - - - -
  announcement_bar();
  function announcement_bar() {
    const BAR = dom('section.announcement');
    if (BAR) {
      const BUTTON = dom('button.announcement-close', BAR);
      BUTTON.addEventListener('click', () => {
        HTML.classList.remove('announcement-out');
        localStorage.setItem('announcement_shown','true');
      });
      if (localStorage.getItem('announcement_shown') !== 'true') {
        setTimeout(() => {
          HTML.classList.add('announcement-out');
        }, 1000);
      }
    }
  };

  // - - - - -
  // Mobile background images
  // - - - - -
  set_mobile_background_images();
  function set_mobile_background_images() {
    const WITH_MBI = dom(['*[data-mbi^="http"]']);
    WITH_MBI.forEach((element) => {
      element.insertAdjacentHTML('afterBegin', '<div class="mobile-background-image" style="background-image: url(' + element.getAttribute('data-mbi') + ');"></div>');
    });
  };

} ); // end no-conflict document ready


// - - - - - - -
// Selects DOM element(s)
// - - - - - - -
// parameters:
//   t  |  String or Array  |  valid CSS selector, or one-element Array containing the same  |  required
//   s  |  Element          |  Element to be searched within                                 |  optional
// returns:
//   Element or Array of Elements
// - - - - - - -
function dom(t,s) {
  if (s === undefined || s === null) {
    s = document;
  }
  const y = (typeof t === 'string') ? s.querySelector(t) : Array.prototype.slice.call(s.querySelectorAll(t[0]));
  return y;
};
